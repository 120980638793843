<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card class="auth-card">
        <!-- logo -->
        <v-card-title class="d-flex align-center justify-center py-7">
          <router-link
            to="/"
            class="d-flex align-center"
          >
            <v-img
              :src="appLogo"
              max-height="30px"
              max-width="30px"
              alt="logo"
              contain
              class="me-3 "
            ></v-img>

            <h2 class="text-2xl font-weight-semibold">
              {{ appName }}
            </h2>
          </router-link>
        </v-card-title>

        <!-- title -->
        <v-card-text>
          <p class="text-2xl mb-5 font-weight-semibold text-center text--primary mb-2">
            👋🏻  مرحبا بك
          </p>
          <p class="mb-2 text-center">
            الرجاء تسجيل الدخول إلى حسابك وبدء العمل
          </p>
        </v-card-text>

        <!-- login form -->
        <v-card-text>
          <v-form
            ref="form"
            v-model="valid"
          >
            <v-text-field
              v-model="email"
              v-max-length="200"
              :rules="emailRules"
              outlined
              label="البريد الالكتروني"
              placeholder="email@example.com"
              hide-details
              autocomplete="off"
              required
              class="mb-3"
            ></v-text-field>

            <v-text-field
              v-model="password"
              v-max-length="200"
              :rules="passwordRules"
              outlined
              :type="isPasswordVisible ? 'text' : 'password'"
              label="كلمة المرور"
              autocomplete="off"
              required
              placeholder="············"
              :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
              hide-details
              @keyup.enter="submitLogin"
              @click:append="isPasswordVisible = !isPasswordVisible"
            >
            </v-text-field>

            <div class="d-flex align-center justify-space-between flex-wrap">
              <v-checkbox
                label="تذكرني"
                hide-details
                class="me-3 mt-1"
              >
              </v-checkbox>
            </div>

            <v-btn
              block
              color="primary"
              class="mt-6"
              :disabled="!valid || loadingSubmit"
              :loading="loadingSubmit"
              @click="submitLogin()"
            >
              دخول
            </v-btn>
          </v-form>
        </v-card-text>

        <!-- Activator -->

        <!-- divider -->
        <v-card-text class="d-flex align-center mt-2">
          <v-divider></v-divider>
          <span class="mx-5">  مركز الكفيل لتقنية المعلومات </span>
          <v-divider></v-divider>
        </v-card-text>
      </v-card>
    </div>
    <!-- background triangle shape  -->
    <img
      class="auth-mask-bg"
      height="173"
      :src="require(`@/assets/images/misc/mask-${$vuetify.theme.dark ? 'dark':'light'}.png`)"
      alt="auth-mask-bg"
    />
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'
import themeConfig from '@themeConfig'
import axios from '@axios'

import toast from '@/store/toast'
import auth from '@/store/auth'
import router from '@/router/index'

export default {

  setup() {
    const isPasswordVisible = ref(false)
    const email = ref('')
    const password = ref('')

    // const socialLink = [
    //   {
    //     icon: mdiFacebook,
    //     color: '#4267b2',
    //     colorInDark: '#4267b2',
    //   },
    //   {
    //     icon: mdiTwitter,
    //     color: '#1da1f2',
    //     colorInDark: '#1da1f2',
    //   },
    //   {
    //     icon: mdiGithub,
    //     color: '#272727',
    //     colorInDark: '#fff',
    //   },
    //   {
    //     icon: mdiGoogle,
    //     color: '#db4437',
    //     colorInDark: '#db4437',
    //   },
    // ]
    return {
      isPasswordVisible,
      email,
      password,

      // socialLink,

      // themeConfig
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,

      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
    }
  },
  data: () => ({
    valid: true,
    loadingSubmit: false,
    password: '',
    passwordRules: [
      v => !!v || 'Password is required',
    ],
    email: '',
    emailRules: [
      v => !!v || 'E-mail is required',
      v => /.+@.+/.test(v) || 'E-mail must be valid',
    ],

  }),
  mounted() {
  },
  methods: {
    async submitLogin() {
      if (this.$refs.form.validate() === false) {
        return
      }
      const userData = {
        email: this.email,
        password: this.password,
      }
      this.loadingSubmit = true;
      try {
        const response = await axios.post('/auth/login', userData)
        const ResSuccess = response.data.success
        if (ResSuccess === true) {
          await auth.commit('SET_USER', response.data.data)
          await router.push('/home');
          await toast.dispatch('success', 'تم تسجيل الدخول بنجاح ')
        }
      } catch (error) {
        // await toast.dispatch('error', error.response ? error.response.data.message : error.message)
      } finally {
        this.loadingSubmit = false;
      }
    },

  },

}
</script>

<style lang="scss">
@import '@core/preset/preset/pages/auth.scss';
</style>
